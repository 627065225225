<template>
  <div :style="{ height: this.height }" class="scroll-box">
    <a-spin :spinning="loading">
      <a-timeline v-if="data.length > 0">
        <a-timeline-item v-for="history in data" :key="history.id">
          <p>
            <a-space>
              <span>{{ history.created_at }}</span>
              <span>{{ history.operation_type }}</span>
              <a-tooltip>
                <template slot="title">
                  {{ history.operator_name }}
                </template>
                <span>
                  <span>({{ history.operator_name | operatorNameFilter }})</span>
                </span>
              </a-tooltip>
            </a-space>
          </p>
          <p>{{ history.operation_content }}</p>
        </a-timeline-item>
      </a-timeline>
      <a-empty v-else />
    </a-spin>
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import { findOperationHistories } from '@/api/operation_history'
import PreviewImage from '@/components/PreviewImage'
export default {
  name: 'OperationHistoryList',
  props: {
    serviceOrderId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  components: {
    PreviewImage
  },
  data() {
    return {
      loading: true,
      data: [],
      previewVisible: false,
      previewImages: [],
      reference_type: ''
    }
  },
  created() {
    this.fetchData()
  },
  filters: {
    operatorNameFilter(value) {
      if (value.length > 11) {
        return value.substring(0, 11) + '...'
      }
      return value
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },
    fetchData() {
      this.loading = true
      if (this.orderType === 'service') {
        this.reference_type = 'ServiceOrder'
      } else if (this.orderType === 'subscribe') {
        this.reference_type = 'SubscribeOrder'
      } else if (this.orderType === 'add_item') {
        this.reference_type = 'AdditionalItemOrder'
      }
      findOperationHistories({ reference_type: this.reference_type, reference_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-box {
  overflow-y: auto;
  padding-top: 5px;
  padding-right: 10px;
}
</style>
